import axios from 'axios';
import { ApiCommonParams } from './../models/app';
import { ICart } from './../models/cart';

export const fetchCart = async ({
  lang,
  country,
  token
}: ApiCommonParams): Promise<ICart | undefined> => {
  const url = `${
    process.env.NEXT_PUBLIC_WP_API_URL || process.env.WP_API_URL
  }/v1/cart`;

  try {
    const { data } = await axios.get<ICart>(url, {
      headers: {
        ['UYN-LANGUAGE']: lang,
        ['UYN-COUNTRY']: country,
        ['UYN-BEARER']: token
      }
    });

    return data;
  } catch (error) {
    console.log(error);
  }
};

export const addProduct = async ({
  sku,
  quantity,
  lang,
  country,
  token
}: ApiCommonParams & {
  sku: string;
  quantity: number;
}): Promise<ICart> => {
  const url = `${
    process.env.NEXT_PUBLIC_WP_API_URL || process.env.WP_API_URL
  }/v1/cart/product`;

  const { data } = await axios.post<ICart>(
    url,
    { product_sku: sku, product_quantity: quantity },
    {
      headers: {
        ['UYN-LANGUAGE']: lang,
        ['UYN-COUNTRY']: country,
        ['UYN-BEARER']: token
      }
    }
  );

  return data;
};

export const updateProduct = async ({
  sku,
  quantity,
  lang,
  country,
  token
}: ApiCommonParams & {
  sku: string;
  quantity: number;
}): Promise<ICart> => {
  const url = `${
    process.env.NEXT_PUBLIC_WP_API_URL || process.env.WP_API_URL
  }/v1/cart/product`;

  try {
    const { data } = await axios.put<ICart>(
      url,
      { product_sku: sku, product_quantity: quantity },
      {
        headers: {
          ['UYN-LANGUAGE']: lang,
          ['UYN-COUNTRY']: country,
          ['UYN-BEARER']: token
        }
      }
    );

    return data;
  } catch (error) {
    return {
      cart: {
        products: [],
        total: 0,
        subtotal: 0,
        remaining: 0,
        gift_products: { products: [], banners: null }
      },
      coupons: []
    };
  }
};

export const removeProduct = async ({
  sku,
  lang,
  country,
  token
}: ApiCommonParams & {
  sku: string;
}): Promise<ICart> => {
  const url = `${
    process.env.NEXT_PUBLIC_WP_API_URL || process.env.WP_API_URL
  }/v1/cart/product`;

  const { data } = await axios.delete<ICart>(url, {
    headers: {
      ['UYN-LANGUAGE']: lang,
      ['UYN-COUNTRY']: country,
      ['UYN-BEARER']: token
    },
    params: {
      product_sku: sku
    }
  });

  return data;
};

export const selectGiftProduct = async ({
  sku,
  promotion_id,
  lang,
  country,
  token
}: ApiCommonParams & {
  sku: string;
  promotion_id: number;
}): Promise<ICart> => {
  const url = `${
    process.env.NEXT_PUBLIC_WP_API_URL || process.env.WP_API_URL
  }/v1/cart/select-gift-product`;

  const { data } = await axios.post<ICart>(
    url,
    { product_sku: sku, promotion_id: promotion_id },
    {
      headers: {
        ['UYN-LANGUAGE']: lang,
        ['UYN-COUNTRY']: country,
        ['UYN-BEARER']: token
      }
    }
  );

  return data;
};

export const clearGiftProduct = async ({
  promotion_id,
  lang,
  country,
  token
}: ApiCommonParams & {
  promotion_id: number;
}): Promise<ICart> => {
  const url = `${
    process.env.NEXT_PUBLIC_WP_API_URL || process.env.WP_API_URL
  }/v1/cart/clear-gift-product`;

  const { data } = await axios.post<ICart>(
    url,
    { promotion_id: promotion_id },
    {
      headers: {
        ['UYN-LANGUAGE']: lang,
        ['UYN-COUNTRY']: country,
        ['UYN-BEARER']: token
      }
    }
  );

  return data;
};

export const emptyCart = async ({ lang, country, token }: ApiCommonParams) => {
  const URL = `${
    process.env.NEXT_PUBLIC_WP_API_URL || process.env.WP_API_URL
  }/v1/cart`;

  return await axios.delete<ICart>(URL, {
    headers: {
      ['UYN-LANGUAGE']: lang,
      ['UYN-COUNTRY']: country,
      ['UYN-BEARER']: token
    }
  });
};
