import axios from 'axios';
import { ResponseWithError } from '../models/app';
import { IProductList } from '../models';

export const searchApi = async ({
  search,
  lang,
  country,
  offset
}: {
  search: string;
  lang: string;
  country: string;
  offset: number;
}): Promise<{ products: IProductList[]; count: number }> => {
  const URL = `${process.env.NEXT_PUBLIC_WP_API_URL}/v1/products/search`;
  const SEARCH_PRODUCTS_LIMIT = Number(process.env.SEARCH_PRODUCTS_LIMIT) || 8;

  try {
    const { data, headers } = await axios.get<{ products: IProductList[] }>(
      URL,
      {
        headers: {
          ['UYN-LANGUAGE']: lang,
          ['UYN-COUNTRY']: country,
          ['UYN-LIMIT']: SEARCH_PRODUCTS_LIMIT,
          ['UYN-OFFSET']: offset
        },
        params: { search }
      }
    );

    return {
      products: data.products || [],
      count: Number(headers['uyn-count']) || 0
    };
  } catch (error) {
    return {
      products: [],
      count: 0
    };
  }
};
