import { ShopPrices } from '../models';
import { ICountryListItem } from '../models/footer';
import { DEFAULT_SYMBOL } from './countries';

export const formatPrice = (
  price: number,
  countries: ICountryListItem[],
  currency?: string
) => {
  let symbol = DEFAULT_SYMBOL;
  const country = countries.find(
    c => c && c.currency && c.currency.toLowerCase() === currency?.toLowerCase()
  );
  if (country) {
    symbol = country['currency-symbol'] || '';
  }
  return `${Number(price).toFixed(2)} ${parseSymbol(symbol)}`;
};

export const getMinPrice = (
  shopPrices: ShopPrices[],
  selling_code: string
): number => {
  const price = shopPrices.find(priceObj => {
    return priceObj.selling_code === selling_code;
  })?.price || { min: 0, max: 0 };
  return price.min || 0;
};

export const parseSymbol = (symbol = '') => {
  switch (symbol) {
    case '&euro;':
      return '€';
    case '&pound;':
      return '£';
    case '&#36;':
      return '$';
    case '&#67;&#72;&#70;':
      return 'CHF ';
    case '&#107;&#114;':
      return 'kr ';
    default:
      return symbol;
  }
};

export const getCurrencySymbol = (
  countries: ICountryListItem[],
  country: string
) => {
  if (country === 'eu' || country === 'int') {
    return '€';
  }

  const currentCountry = countries.find(c => {
    return c['country-code']?.toLowerCase() === country.toLowerCase();
  });

  return parseSymbol(
    currentCountry ? currentCountry['currency-symbol'] : DEFAULT_SYMBOL
  );
};

export const getCurrencyName = (
  countries: ICountryListItem[],
  country: string
) => {
  if (country === 'eu' || country === 'int') {
    return 'EUR';
  }

  const currentCountry = countries.find(c => {
    return c['country-code']?.toLowerCase() === country.toLowerCase();
  });

  return currentCountry ? currentCountry['currency'] : 'GBP';
};
