import { Countries, Districts } from './../models/mapping';
import axios from 'axios';
import { ApiCommonParams } from '../models/app';
import { ColorsCodeToLabel } from '../models/mapping';
import data from '../public/colors.json';
import colors_map from '../public/colors/colors_map.json';

export const fetchColors = (): ColorsCodeToLabel => {
  return data;
};

export const fetchColorsForFilters = (): ColorsCodeToLabel => {
  return colors_map;
};

export const fetchCountries = async ({
  lang,
  country,
  token
}: ApiCommonParams): Promise<Countries> => {
  const url = `${
    process.env.NEXT_PUBLIC_WP_API_URL || process.env.WP_API_URL
  }/v1/layout/contries-list`;

  const config = {
    headers: {
      ['UYN-LANGUAGE']: lang,
      ['UYN-COUNTRY']: country,
      ['UYN-BEARER']: token
    }
  };

  try {
    const { data } = await axios.get(url, config);

    return data.countries;
  } catch (error) {
    return {};
  }
};

export const fetchDistricts = async ({
  country_code,
  lang,
  country,
  token
}: ApiCommonParams & { country_code: string }): Promise<Districts> => {
  const url = `${
    process.env.NEXT_PUBLIC_WP_API_URL || process.env.WP_API_URL
  }/v1/layout/states-list`;

  const config = {
    headers: {
      ['UYN-LANGUAGE']: lang,
      ['UYN-COUNTRY']: country,
      ['UYN-BEARER']: token
    },
    params: {
      country_code
    }
  };

  try {
    const { data } = await axios.get(url, config);

    return data.states;
  } catch (error) {
    return {};
  }
};
