// import axios from 'axios';
// import { ApiCommonParams } from '../models/app';
// import { MenuItem } from '../models/menu';

// export const fetchMenu = async ({
//   lang,
//   country,
//   token
// }: ApiCommonParams): Promise<MenuItem[]> => {
//   const config = {
//     headers: {
//       ['UYN-LANGUAGE']: lang,
//       ['UYN-COUNTRY']: country,
//       ['UYN-BEARER']: token
//     }
//   };

//   const url = `${
//     process.env.NEXT_PUBLIC_WP_API_URL || process.env.WP_API_URL
//   }/v1/layout/menu`;

//   try {
//     const { data: { items = [] } = {} } = await axios.get(url, config);
//     console.log(JSON.stringify(items, null, 2));
//     return items;
//   } catch (err) {
//     console.log(`err`, err);
//     return [];
//   }
// };

import { DEFAULT_LANG, STRING_LANG } from '../helpers/languages';
import { IColumnMenu, IItemMenu, MenuItem } from '../models/menu';
import data from '../public/menu.json';

export const fetchMenu = ({
  lang = DEFAULT_LANG,
  order
}: {
  lang: STRING_LANG;
  order: 'mobile' | 'desktop';
}): MenuItem[] => {
  const { items = [] } = data[lang];
  orderItems(items, order);
  return items;
};

const orderItems = (items: MenuItem[], order: 'mobile' | 'desktop') => {
  const sorting = (
    a: MenuItem | IItemMenu | IColumnMenu,
    b: MenuItem | IItemMenu | IColumnMenu
  ) => {
    const posA = a.order ? a.order[order] : 0;
    const posB = b.order ? b.order[order] : 0;
    return posA - posB;
  };

  items.sort(sorting);

  for (const item of items) {
    if (item.columns) {
      item.columns.sort(sorting);
      for (const column of item.columns) {
        if (column.items) {
          column.items.sort(sorting);
          for (const subItem of column.items) {
            if (subItem.items) {
              subItem.items.sort(sorting);
            }
          }
        }
      }
    }
  }
};
