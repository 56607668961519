import React from 'react';
import { getLangCountry } from '../../helpers/languages';
import useQueryParams from '../../hooks/useQueryParams';
import { GiftBanner } from '../../models/cart';
import PrimaryButtonSmall from '../PrimaryButtonSmall';

interface Props extends GiftBanner {
  onClickFunction?: () => void;
}

const BannerProduct: React.FC<Props> = ({
  title,
  disclaimer,
  text,
  button_text,
  button_link,
  button_text_2,
  button_link_2,
  image,
  product_slug,
  onClickFunction
}) => {
  const [query] = useQueryParams();
  const { lang, country } = getLangCountry(query);
  if (product_slug) {
    button_link = `/${lang}_${country}/${product_slug}`;
  }

  return (
    <div className="banner-product-gradient md:flex md:space-y-0 rounded-lg ">
      <div className="flex-1 flex flex-col justify-center px-4 py-4 lg:py-6">
        <div>
          <div className="bg-primary text-white uppercase font-bold inline-flex text-xs px-2 py-1 rounded-sm mb-3">
            {title}
          </div>
        </div>
        <p
          className="text-primary font-sans font-bold text-2xl mb-1 text-center lg:text-left"
          dangerouslySetInnerHTML={{
            __html: text
          }}
        ></p>

        {disclaimer && (
          <p
            className="text-sm text-center lg:text-left"
            dangerouslySetInnerHTML={{
              __html: disclaimer
            }}
          ></p>
        )}

        <div className="flex gap-x-2">
          {button_text && button_text.length > 0 && (
            <div
              className="mt-2 lg:mt-4 lg:inline-flex"
              onClick={() => {
                if (onClickFunction) {
                  onClickFunction();
                }
              }}
            >
              <PrimaryButtonSmall text={button_text} href={button_link} />
            </div>
          )}

          {button_text_2 && button_text_2.length > 0 && (
            <div className="mt-2 lg:mt-4 lg:inline-flex">
              <PrimaryButtonSmall text={button_text_2} href={button_link_2} />
            </div>
          )}
        </div>
      </div>

      <div className="flex justify-center items-center lg:pl-24">
        <img src={image} alt="Banner product" className="w-40 h-40" />
      </div>
    </div>
  );
};

export default BannerProduct;
