import React, { useState } from 'react';
import { UserIcon } from '@heroicons/react/solid';
import MenuCart from './MenuCart';
import LinkTo from '../LinkTo';
import localizedRoute from '../../utils/localizedRoute';
import MenuSearch from './MenuSearch';
import { isLogged } from '../../helpers/auth';

export default function MenuActions() {
  const logged = isLogged();
  const [isSearchOpen, setIsSearchOpen] = useState(false);

  return (
    <>
      <MenuSearch isOpen={isSearchOpen} setIsOpen={setIsSearchOpen} />

      <MenuCart isSearchOpen={isSearchOpen} />

      <div className="ml-3 relative hidden lg:block">
        <LinkTo
          href={localizedRoute(logged ? '/profile/home' : 'login')}
          className="bg-white rounded-full flex text-sm focus:outline-none hover:text-gray-500"
        >
          <UserIcon className="w-6 h-6" aria-hidden="true" />
        </LinkTo>
      </div>
    </>
  );
}
