import React from 'react';

interface ButtonProps {
  text: string;
  onClick?: () => void;
}

const GrayButton: React.FC<ButtonProps> = ({ text, ...props }: ButtonProps) => {
  return (
    <button
      {...props}
      className="text-center bg-gray-100 text-black px-3 py-4 rounded-lg border border-grey-300 cursor-pointer"
    >
      {text}
    </button>
  );
};

export default GrayButton;
