import React from 'react';

const MenuBadge = ({ text }: { text: string }) => {
  const isTop = text.toLowerCase() === 'top';

  return (
    <div
      className={`${
        isTop ? 'bg-lightorange' : 'bg-lightyellow'
      } py-1 px-2 ml-2 rounded-md`}
    >
      <span
        className={`${
          isTop ? 'text-orange' : 'text-primary'
        } text-12 font-bold `}
      >
        {text}
      </span>
    </div>
  );
};

export default MenuBadge;
