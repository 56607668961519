import { useCookies } from 'react-cookie';

export const isLogged = () => {
  const [cookies] = useCookies(['jeep_authenticated']);
  return cookies['jeep_authenticated'] === 'true';
};

export const getToken = () => {
  const [cookies] = useCookies(['jeep_bearer']);
  return cookies['jeep_bearer'];
};
